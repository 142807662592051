<template>
    <results-layout>
        <v-container
            v-if="visualizationsArray && visualizationsArray.length"
            class="pb-12 px-8"
        >
            <div class="my-8 text-h5">Visualizations</div>

            <div class="d-flex flex-grow-1 flex-wrap">
                <v-card
                    @click="handleItemClick(visualizationItem.id)"
                    outlined
                    class="mr-4 mb-4"
                    width="250"
                    v-for="visualizationItem in visualizationsArray"
                    :key="visualizationItem.id"
                    :loading="visualizationItem.status==='processing'"
                    :disabled="visualizationItem.status==='failed'"
                >
                    <template slot="progress">
                        <v-progress-linear
                            color="primary"
                            height="4"
                            indeterminate
                        ></v-progress-linear>
                    </template>
                    <v-img
                        height="250"
                        :src="
                            visualizationItem.cover_image_url ||
                                'https://cdn.voiceform.com/media/visualization-default-cover.png'
                        "
                    ></v-img>
                    <div class="d-flex align-center pa-4">
                        <v-chip
                            small
                            :color="getColorFromStatus(visualizationItem.status)"
                            >{{ visualizationItem.status }}</v-chip
                        >
                        <v-spacer></v-spacer>
                        <span class="caption grey--text text">{{
                            $date(visualizationItem.created_at).fromNow()
                        }}</span>
                    </div>
                </v-card>
            </div>
        </v-container>
        <v-container v-else-if="!visualizationsArray">
            <div class="d-flex flex-grow-1 flex-wrap">
                <v-skeleton-loader
                    v-for="index in 10"
                    :key="`visualization-loader-${index}`"
                    width="250"
                    class="mr-4 mb-4"
                    type="card"
                ></v-skeleton-loader>
            </div>
        </v-container>
        <v-container class="d-flex flex-column align-center" v-else>
            <img
                class="mt-16"
                src="https://cdn.voiceform.com/media/no-visualization-illustration.svg"
                style="height: 300px"
            />
            <div class="mt-8 mb-6 text-h5 text-center">
                No visualizations, yet!
            </div>
            <div class="text-center">
                To create a visualization, select "Visualize" on your audio
                answers.
            </div>
            <img
                class=" explanation-illustration mt-5 mx-4"
                src="https://cdn.voiceform.com/media/visualization-explanation.svg"
                max-width="600"
            />
        </v-container>
        <v-dialog
            v-model="videoDialog"
            width="500"
        >
            <v-card v-if="selectedVisualization" class="d-flex flex-column relative" rounded>
                <div v-if="selectedVisualization.status !== 'processing'">

                    <div
                        class="player-container"
                    >
                        <vue-core-video-player
                            :cover="
                                selectedVisualization.cover_image_url ||
                                    'https://cdn.voiceform.com/media/visualization-default-cover.png'
                            "
                            height="500px"
                            width="500px"
                            v-if="selectedVisualization"
                            :src="selectedVisualization.video_url"
                        ></vue-core-video-player>
                    </div>
                    <div class="pa-4">
                        <v-btn
                            width="100%"
                            elevation="0"
                            color="primary"
                            @click="downloadVideo"
                            large
                        >
                            <v-icon left>mdi-download</v-icon>
                            Download MP4 Video
                        </v-btn>
                    </div>
                </div>
                <div
                    v-else
                    class="d-flex flex-column align-center flex-grow-1 justify-center py-8 px-5 text-center"
                >
                    <div class="text-h6 mb-5">Your video is processing!</div>
                    <div class="text-body-1" style="max-width: 350px;">
                        This may take around 5-10 minutes. We will send you an email notification when it’s ready.
                        📩
                    </div>
                </div>
            </v-card>
        </v-dialog>
    </results-layout>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ResultsLayout from "../../../../layouts/ResultsLayout.vue";

export default {
    name: "Visualizations",
    data() {
        return {
            videoDialog: false,
            selectedVisualizationId: this.$route.query.id || null,
        };
    },
    watch: {
        videoDialog(value){
            if(!value){
                const query = {...this.$route.query}
                delete query.id;
                this.$router.push({ query})
            }
        }
    },
    components: {
        ResultsLayout
    },
    computed: {
        ...mapGetters({
            visualizationsArray: "visualization/getVisualizationsArray"
        }),

        selectedVisualization() {
            if (this.selectedVisualizationId && this.$store.state.visualization.visualizations) {
                return this.$store.state.visualization.visualizations[
                    this.selectedVisualizationId
                ] || null;
            }
            return null
        }
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        ...mapActions({
            listVisualizations: "visualization/listVisualizations"
        }),
        getColorFromStatus(status){
            if(status === 'complete'){
                return 'primary'
            }
            if(status === 'processing'){
                return 'grey lighten-4'
            }

            return 'error'
        },
        handleItemClick(visualizationId) {
            this.selectedVisualizationId = visualizationId;
            this.$router.push({ query: {...this.$route.query, id: visualizationId }})
            this.videoDialog = true;
        },
        downloadVideo() {
            if (this.selectedVisualization) {
                var xhr = new XMLHttpRequest();

                // hack to avoid cache
                const videoSrc = new URL(this.selectedVisualization.video_url);
                videoSrc.searchParams.append('cors', Date.now());

                xhr.open("GET", videoSrc.href, true);
                xhr.responseType = "blob";

                xhr.setRequestHeader('access-control-allow-origin', '*')

                const parent = this;

                xhr.onload = function() {
                    var urlCreator = window.URL || window.webkitURL;
                    var videoUrl = urlCreator.createObjectURL(this.response);
                    var tag = document.createElement("a");
                    tag.href = videoUrl;
                    tag.target = "_blank";
                    tag.download = `visualization-${parent.selectedVisualization.id}.mp4`;
                    document.body.appendChild(tag);
                    tag.click();
                    document.body.removeChild(tag);
                };
                xhr.onerror = () => {
                    console.error('error in visualization download' );
                    parent.showSnackBar({
                        text: 'Whoops, looks like something went wrong, and we cannot download this file. \nTry reopening this window or try cleaning your browser cache.',
                        color: 'error',
                        timeout: 4000,
                    });
                };
                xhr.send();
            }
        }
    },
    updated(){
        if(this.$store.state.visualization.visualizations && this.$route.query && this.$route.query.id){
            setTimeout(()=>{
                this.videoDialog = true
            }, 300)
        }
    },
    mounted(){
        this.$vuetify.goTo(0)
    },
    beforeMount() {
        this.listVisualizations(this.$route.params.surveyId);
    }
};
</script>

<style lang="scss" scoped>
.player-container {
    position: relative;
    width: 500px;
    height: 500px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.explanation-illustration {
    max-width: 600px;
}

</style>
